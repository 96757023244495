<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium" :class="isRTL ? 'ml-auto' : 'mr-auto'">
        {{ i18n('iam.userDetails.title') }}
      </h2>
    </div>

    <!-- BEGIN: Profile Info -->
    <div class="intro-y box px-5 pt-5 mt-5">
      <div
        style="width: 100%; height: 30vh"
        class="flex justify-center"
        v-if="loading"
      >
        <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
      </div>
      <div v-if="!loading && record">
        <div
          class="
            flex flex-col
            lg:flex-row
            border-b border-gray-200
            dark:border-dark-5
            pb-5
            -mx-5
          "
        >
          <div
            class="
              flex flex-1
              px-5
              items-center
              justify-center
              lg:justify-start
            "
          >
            <div
              class="
                w-20
                h-20
                sm:w-24 sm:h-24
                flex-none
                lg:w-32 lg:h-32
                image-fit
                relative
              "
            >
              <img
                alt="Icewall Tailwind HTML Admin Template"
                class="rounded-full"
                :src="
                  record.avatar
                    ? record.avatar
                    : require(`@/assets/images/${$f()[0].photos[0]}`)
                "
              />
            </div>
            <div class="ml-5 mr-5">
              <div
                class="w-24 sm:w-40 sm:whitespace-normal font-medium text-lg"
              >
                {{ record.userName }}
              </div>
            </div>
          </div>
          <div
            class="
              mt-6
              lg:mt-0
              flex-1
              dark:text-gray-300
              px-5
              border-l border-gray-200
              dark:border-dark-5
              border-t
              lg:border-t-0
              pt-5
              lg:pt-0
            "
          >
            <div
              class="font-medium text-center lg:mt-3"
              :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
            >
              {{ i18n('iam.userDetails.contactDetails') }}
            </div>
            <div
              class="
                flex flex-col
                justify-center
                items-center
                lg:items-start
                mt-4
              "
            >
              <div class="truncate sm:whitespace-normal flex items-center">
                <MailIcon class="w-4 h-4 mr-2 ml-2" />
                {{ record.email }}
              </div>
              <div class="truncate sm:whitespace-normal flex items-center mt-3">
                <PhoneIcon class="w-4 h-4 mr-2 ml-2" />
                <span style="direction: ltr !important">{{
                  record.phoneNumber
                }}</span>
              </div>
            </div>
            <div
              class="font-medium text-center lg:mt-3"
              :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
            >
              {{ i18n('iam.userDetails.info') }}
            </div>
            <div
              class="
                flex flex-col
                justify-center
                items-center
                lg:items-start
                mt-4
              "
            >
              <div class="truncate sm:whitespace-normal flex items-center gap-2">
                <span>Max Number of Menus :</span>
                <span>{{ record.maxMenus }}</span>
              </div>
              <div class="truncate sm:whitespace-normal flex items-center gap-2 mt-3">
                <span>Number of Menus Used :</span>
                <span>{{ record.menusUsed }}</span>
              </div>
              <div class="truncate sm:whitespace-normal flex items-center gap-2 mt-3">
                <span>Hosting URL :</span>
                <span>{{ record.hostingURL }}</span>
              </div>
            </div>
            <div
              class="font-medium text-center lg:mt-3"
              :class="isRTL ? 'lg:text-right' : 'lg:text-left'"
            >
              {{ i18n('iam.userDetails.status') }}
            </div>
            <div
              class="
                flex flex-col
                justify-center
                items-center
                lg:items-start
                mt-4
              "
            >
              <div
                class="truncate sm:whitespace-normal flex items-center"
                :class="{
                  'text-theme-10 dark:text-theme-30': activated,
                  'text-theme-24': !activated
                }"
              >
                {{
                  activated ? i18n('common.activated') : i18n('common.disabled')
                }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="
            nav nav-tabs
            flex-col
            sm:flex-row
            justify-center
            lg:justify-start
          "
          role="tablist"
        >
          <a
            id="requests-tab"
            data-toggle="tab"
            data-target="#requests"
            href="javascript:;"
            class="py-4 sm:mr-8 active"
            role="tab"
            aria-controls="requests"
            aria-selected="true"
            @click="tab = 'requests'"
          >
            {{ i18n('iam.userDetails.requests') }}
          </a>
        </div> -->
      </div>
    </div>
    <!-- END: Profile Info -->
    <!-- <div class="intro-y tab-content mt-5">
      <RequestsTab v-if="tab == 'requests'" />
    </div> -->
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
// import RequestsTab from '@/views/users/requests-tab.vue'
import { UserModel } from '@/store/user/user-model'
const { fields } = UserModel

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    // RequestsTab
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      // record: 'user/view/record',
      loading: 'user/view/loading'
    }),
    fields() {
      return fields
    },
    activated() {
      return this.record && this.record.status === 'enabled'
    }
  },
  setup() {
    const tab = ref('requests')
    const record = ref({
      id: 'eZITEKhYBGPbemmNoRhKkrc71j52',
      userName: 'ahmed',
      phoneNumber: '+201061834095',
      email: 'ahmed@example.com',
      avatar: null,
      maxMenus: 4,
      menusUsed: 2,
      tempPassword: 'TEKhYBGPbemmNoRhKk',
      hostingURL: 'localhost:8081',
      authenticationUid: 'eZITEKhYBGPbemmNoRhKkrc71j52',
      deviceTokens: ['2e8f99f9d81ca6732969e177a1c0a2995408cb29'],
      lang: 'en',
      role: 'user',
      status: 'enabled',
      pagesAccess: [],
      notification: true,
      createdBy: 'eZITEKhYBGPbemmNoRhKkrc71j52',
      createdAt: '2022-08-14T16:14:16.153Z',
      updatedBy: 'eZITEKhYBGPbemmNoRhKkrc71j52',
      updatedAt: '2022-08-14T16:14:16.153Z'
    })
    return {
      tab,
      record
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  async mounted() {
    await this.doFind(this.id)
    console.log('record', this.record)
  },
  methods: {
    ...mapActions({
      doFind: 'user/view/doFind'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    }
  }
})
</script>
